<template>
  <v-form
    ref="form"
    class="pa-2"
    @submit.prevent="onSave"
  >

    <text-field
      v-model="model.name"
      :rules="rules.name"
      :label="$t('inputs.name.label')"
    />

    <api-field
      v-model="model.parent"
      :label="$t('inputs.parent.label')"
      :rules="rules.parent"
      api="area"
      item-search="name"
      clearable
    />

    <select-field
      v-model="model.status"
      :label="$t('inputs.status.label')"
      :rules="rules.status"
      :items="statusItems"
    />

    <checkbox
      v-model="model.app"
      :label="$t('inputs.app.label')"
      :true-value="1"
      :false-value="0"
    />

  </v-form>
</template>

<script>
import Checkbox from '@/components/form/Checkbox';
import TextField from '@/components/form/TextField';
import SelectField from '@/components/form/SelectField';
import ApiField from '@/components/form/ApiField';
import { required } from '@/utils/rules';
import { mapGetters } from 'vuex';

export default {
  components: { TextField, SelectField, ApiField, Checkbox },
  props: {
    value: Object,
    save: Function
  },
  data() {
    return {
      model: this.value || {}
    }
  },
  computed: {
    ...mapGetters( 'app', [ 'statusItems' ]),
    rules() {
      return {
        name: [
          required( this.$t('inputs.name.rules.required'))
        ]
      };
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
    }
  },
  methods: {
    onSave() {
      this.$emit( 'input', this.model );
      this.save && this.$nextTick( this.save );
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>
