<template>
  <table-view
    ref="table"
    api="area"
    v-model="dialog"
    :filters="filters"
    :headers="headers"
    :popup="popup"
    :save="save"
    :edit="edit"
    :compute="computeAreas"
    @click:item="edit( $event.item.item )"
    @filters="hasFilters = !!$event.length"
    ignore-status
  >
  <template v-slot:form>

    <area-form
      ref="form"
      v-model="model"
      :save="save"
    />

  </template>
  <template v-slot:item.value.name="{ value, item }">

  <template v-if="item.tab">
    <span v-for="n in item.tab" :key="n">&#8212;</span>
  </template> {{ value }}

  </template>
  <template v-slot:item.value.app="{ value }">

    <status :value="value"/>

  </template>
  <template v-slot:item.value.status="{ value }">

    <status :value="value"/>

  </template>
  <template v-slot:footer>

    <v-spacer/>

    <btn
      class="mr-2"
      color="primary"
      :loading="exporting"
      @click.stop="exportData"
    >
      {{ $t('btn.export') }}
    </btn>

    <btn @click.stop="edit()">
      {{ $t('area.btn.new') }}
    </btn>

  </template>
  </table-view>
</template>

<script>
import TableView from '@/components/TableView';
import Status from '@/components/values/Status';
import AreaForm from './Form';
import { ICONS } from '@/utils/constants';
import { computeAreas } from './utils';
import { mapGetters } from 'vuex';

export default {
  name: 'Areas',
  auth: true,
  head: vm => ({ title: vm.$i18n.t('area.title') }),
  layout: 'admin',
  layoutProps: ({ i18n }) => ({
    title: i18n.t('area.title'),
    icon: ICONS.area
  }),
  components: { TableView, Status, AreaForm },
  data: () => ({
    model: {},
    dialog: false,
    exporting: false,
    hasFilters: false,
    popup: {}
  }),
  computed: {
    ...mapGetters( 'app', [ 'statusItems' ]),
    headers() {
      return [
        {
          key: 'name',
          text: this.$t('inputs.name.label'),
          mobile: true
        },
        {
          key: 'app',
          text: this.$t('inputs.app.label'),
          width: 1,
          align: 'center',
          filter: {
            is: 'select-field',
            clearable: true,
            items: this.statusItems
          }
        },
        {
          key: 'status',
          text: this.$t('inputs.status.label'),
          mobile: true,
          width: 1,
          align: 'center',
          filter: {
            is: 'select-field',
            clearable: true,
            items: this.statusItems
          }
        }
      ];
    },
    filters() {
      return !this.hasFilters
        ? [{ field: 'parent', stringValue: 'null' }]
        : [];
    },
  },
  methods: {
    computeAreas,
    edit( item ) {

      this.dialog = true;
      this.popup = {
        ...this.popup,
        title: this.$t(`area.btn.${ item ? 'edit': 'new' }`)
      };

      /*if ( item ) {
        this.$router.replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, id: item.id }
        }).catch(() => {});
      }*/

      this.$nextTick(() => {
        this.$refs.form.resetValidation();
        this.model = {
          id: -1,
          status: 1,
          app: 0,
          ...item
        };
      });
    },
    sanitize( model ) {
      return {
        ...model,
        parent: (model.parent ? model.parent.id || model.parent : 0) || null,
        subareas: undefined
      };
    },
    exportData() {
      if ( this.exporting ) return;
      this.exporting = true;
      this.$refs.table.exportData().finally(() => {
        this.exporting = false;
      });
    },
    save() {
      if ( this.$refs.form.validate()) {
        this.dialog = false;
        this.model = this.$refs.form.model;
        this.$store
          .dispatch('api/area/set', this.sanitize( this.model ))
          .then(() => this.$refs.table.refresh())
          .then(() => {
            this.$store.dispatch( 'app/success', {
              message: this.$t('alerts.save')
            });
          }).catch( err => {
            this.$store.dispatch( 'app/error', {
              message: err.message
            });
          });
      }
    }
  }
}
</script>
