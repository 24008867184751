<template>
  <v-icon :color="color" small>
    {{ icon }}
  </v-icon>
</template>

<script>
export default {
  props: {
    value: Number,
    trueValue: {
      type: null,
      default: 1
    },
    blockValue: Number
  },
  computed: {
    color() {
      if ( this.value === this.trueValue ) return 'success';
      if ( this.value === this.blockValue ) return 'info';
      return 'grey lighten-2';
    },
    icon() {
      if ( this.value === this.trueValue ) return 'mdi-checkbox-marked-circle';
      if ( this.value === this.blockValue ) return 'mdi-lock';
      return 'mdi-close-circle';
    }
  }
}
</script>
