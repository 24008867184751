var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-view',{ref:"table",attrs:{"api":"area","filters":_vm.filters,"headers":_vm.headers,"popup":_vm.popup,"save":_vm.save,"edit":_vm.edit,"compute":_vm.computeAreas,"ignore-status":""},on:{"click:item":function($event){return _vm.edit( $event.item.item )},"filters":function($event){_vm.hasFilters = !!$event.length}},scopedSlots:_vm._u([{key:"form",fn:function(){return [_c('area-form',{ref:"form",attrs:{"save":_vm.save},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"item.value.name",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.tab)?_vm._l((item.tab),function(n){return _c('span',{key:n},[_vm._v("—")])}):_vm._e(),_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.value.app",fn:function(ref){
var value = ref.value;
return [_c('status',{attrs:{"value":value}})]}},{key:"item.value.status",fn:function(ref){
var value = ref.value;
return [_c('status',{attrs:{"value":value}})]}},{key:"footer",fn:function(){return [_c('v-spacer'),_c('btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.exporting},on:{"click":function($event){$event.stopPropagation();return _vm.exportData.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.export'))+" ")]),_c('btn',{on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},[_vm._v(" "+_vm._s(_vm.$t('area.btn.new'))+" ")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }